import { useMaestroToast } from '@maestro/components';
import { StoryFactory, useCreateStoryFactoryMutation } from '@maestro/graphql';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { StoryFactoryForm } from './StoryFactoryForm';

export const StoryFactoryCreate: React.FC = () => {
  const { mutateAsync: createFactory } = useCreateStoryFactoryMutation();
  const [isSaving, setSaving] = useState(false);
  const toast = useMaestroToast();
  const navigate = useNavigate();

  const save = async (
    factory: Pick<StoryFactory, 'prompt' | 'fields' | 'title'>,
  ) => {
    const { prompt, fields, title } = factory;
    const factoryStrategy = 'v1'; // TODO fix this while we don't support others

    setSaving(true);
    try {
      const result = await createFactory({
        input: { title, prompt, fields, factoryStrategy },
      });

      if (result.createFactory.storyFactory?.id) {
        toast({ title: 'Factory created', status: 'success' });
        navigate(`/story-factory/${result.createFactory.storyFactory.id}`);
      }
    } finally {
      setSaving(false);
    }
  };

  return (
    <StoryFactoryForm
      formTitle="Creating Story Factory"
      title=""
      prompt=""
      fields={[]}
      onSave={save}
      onCancel={() => navigate('/story-factory')}
      isSaving={isSaving}
    />
  );
};
